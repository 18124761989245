<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-full mb-base">
      <vx-card title="User Management">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" :to="{name: 'users-create', query:{contact_id: this.contact_id}}">Add User</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="contact_name">Contact</vs-th>
            <vs-th sort-key="role_name">Role</vs-th>
            <vs-th sort-key="is_admin">Type</vs-th>
            <vs-th sort-key="is_active">Status</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">
                <router-link :to="{name:'users-show', params:{id: tr.id}}">{{tr.name}}</router-link>
              </vs-td>
              <vs-td label="Email" :data="tr.email">{{tr.email}}</vs-td>
            <vs-td label="Contact" :data="tr.contact_name">{{tr.contact_name}}</vs-td>
              <vs-td label="Role" :data="tr.role_name" >{{tr.role_name}}</vs-td>

              <vs-td :data="tr.is_admin" class='md-mb-6'>
                <vs-chip v-if="tr.is_admin" color="primary">Administrator</vs-chip>
                <vs-chip v-if="!tr.is_admin">Member</vs-chip>
              </vs-td>
              <vs-td :data="tr.is_active">
                <vs-chip size="small" v-if="tr.is_active" color="success"><vs-avatar color="success" icon-pack="feather" icon="icon-check"></vs-avatar> ACTIVE</vs-chip>
                <vs-chip size="small" v-if="!tr.is_active" color="danger"><vs-avatar color="danger" icon-pack="feather" icon="icon-x"></vs-avatar> NOT ACTIVE</vs-chip>                
              </vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'users-edit', params:{id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button v-if="tr.id != activeUserInfo.id" color='danger' size="small" type="line" @click="deletes(tr.id)" icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  props : ['contact_id'],
  data(){
    return {
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.users.rows
    }),
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'users/index',
      dispatchDestroy: 'users/destroy'
    }),
    async getData(){
      let payload = {
        contact_id: this.contact_id,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>